import React from "react"
import { Link, withPrefix, graphql } from "gatsby"
import Layout from "../components/layouts/layout"
import HeadMeta from "../components/layouts/headmeta"
import ProgressBar from "../components/layouts/progressbar"
import { changeProgressBarOnScroll, slugify } from "../components/utils/index"

export default class SupportIndexPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Support", "Index")
  }

  render() {
    const { data } = this.props
    const { group: categories, distinct: folders } = data.allMarkdownRemark
    const metadata = {
      title: "Customer Care & Support | Advanx Health",
      description:
        "Interested in getting Advanx Health DNA Explorer? Get your questions answered here.",
      image: "https://www.advanxhealth.com/img/share/blog-index.png",
      url: "https://www.advanxhealth.com/support",
    }

    return (
      <Layout>
        <div className="index">
          <HeadMeta metadata={metadata} />
          <ProgressBar />
          <section className="bg-light">
            <div className="container">
              <div className="text-center">
                <h1 className="text-primary">Need Help?</h1>
                <div className="row my-5">
                  <div className="col-lg-4 offset-lg-2 py-3">
                    <div className="card h-100 bg-transparent">
                      <div className="card-body text-center d-flex flex-column">
                        <div
                          className="icon "
                          style={{ padding: "2rem 0.5rem" }}
                        >
                          <img
                            src={withPrefix("/img/lp-icons/icon-support.svg")}
                            className="lp-icons img-fluid"
                            alt="support"
                          />
                        </div>
                        <h4 className="mb-3">Search Help Guide</h4>
                        <p className="lead mb-5">
                          Search for answers from a list of commonly asked
                          questions on kit usage, shipping, product information,
                          and more.
                        </p>
                        <div className="mt-auto">
                          <Link
                            className=" btn btn-pink"
                            to={withPrefix("/support#support")}
                          >
                            Search Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 py-3">
                    <div className="card h-100 bg-transparent">
                      <div className="card-body text-center d-flex flex-column">
                        <div
                          className="icon "
                          style={{ padding: "2rem 0.5rem" }}
                        >
                          <img
                            src={withPrefix("/img/lp-icons/icon-call.svg")}
                            className="lp-icons img-fluid"
                            alt="call"
                          />
                        </div>
                        <h4 className="mb-3">Contact Us</h4>
                        <p className="lead mb-5">
                          Need urgent help? Drop us a call at{" "}
                          <b className="font-weight-bold">+60 11-5693 9124</b>
                          <br />
                          <br />
                          Alternatively, you may also email us directly at:
                          <br />
                          <b className="font-weight-bold">
                            hello@advanxhealth.com
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="container" id="support">
            <h1 className="text-center mb-5">Customer Care & Support</h1>
            {folders.map(folder => {
              let sortedCategories = categories.sort((a, b) =>
                a.categoryorder > b.categoryorder ? 1 : -1
              )
              return (
                <div className="container">
                  <div className="row mb-5">
                    <div className="col-md-4">
                      <h3 className="text-primary-pink">{folder}</h3>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        {sortedCategories.map(
                          ({ fieldValue: categoryName, edges: posts }) => {
                            let xx = posts.filter(
                              post =>
                                post.node.frontmatter.foldertitle === folder
                            )
                            if (xx.length > 0) {
                              return (
                                <div className="col-md-6 mb-3">
                                  <strong className="text-primary">
                                    {categoryName}
                                  </strong>
                                  <hr />
                                  {xx.map(({ node: post }) => {
                                    let postLink = slugify(
                                      "support/" +
                                        folder +
                                        "/" +
                                        categoryName +
                                        "/" +
                                        post.frontmatter.title
                                    )
                                    return (
                                      <p>
                                        <Link to={withPrefix(postLink)}>
                                          {post.frontmatter.title}
                                        </Link>
                                      </p>
                                    )
                                  })}
                                </div>
                              )
                            }
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query SupportIndexQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "support-page" } } }
      sort: {
        order: ASC
        fields: [frontmatter___folderorder, frontmatter___categoryorder]
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            templateKey
            foldertitle
            folderorder
            categorytitle
            categoryorder
          }
        }
      }
      distinct(field: frontmatter___foldertitle)
      group(field: frontmatter___categorytitle) {
        fieldValue
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              templateKey
              foldertitle
              folderorder
              categorytitle
              categoryorder
            }
          }
        }
      }
    }
  }
`
